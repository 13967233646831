import React from "react";
import "./Navigation.css";

const Navigation = ({ activeTab, setActiveTab }) => {
  return (
    <nav>
      <button
        className={activeTab === "hookah" ? "active" : ""}
        onClick={() => setActiveTab("hookah")}
      >
        Hookah Flavors
      </button>
      <button
        className={activeTab === "drinks" ? "active" : ""}
        onClick={() => setActiveTab("drinks")}
      >
        Drinks
      </button>
      <button
        className={activeTab === "desserts" ? "active" : ""}
        onClick={() => setActiveTab("desserts")}
      >
        Desserts
      </button>
    </nav>
  );
};

export default Navigation;
