import React from "react";
import "./Header.css";
import logo from "../assets/logo.png";

const Header = ({ scrollToFlavors }) => {
  return (
    <header>
      <img src={logo} alt="Kings Lounge Logo" className="logo" />
      <h1>Welcome to Kings Lounge</h1>
      <p>Experience elegance, flavor, and relaxation like never before.</p>
      <button
        className="explore-button"
        onClick={scrollToFlavors}
        aria-label="Explore our hookah flavors"
      >
        Explore our flavors
      </button>
    </header>
  );
};

export default Header;
