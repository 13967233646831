import React from "react";
import "./Desserts.css";
import comingsoon from "../assets/comingsoon.png";

const Desserts = () => {
  return (
    <div className="content">
      <h2>Desserts</h2>
      <div className="menu-grid">
        <div className="menu-item">
          <img src={comingsoon} alt="Dessert Item" />
          <p>Chocolate Cake</p>
        </div>
        <div className="menu-item">
          <img src={comingsoon} alt="Dessert Item" />
          <p>Fruit Tart</p>
        </div>
        <div className="menu-item">
          <img src={comingsoon} alt="Dessert Item" />
          <p>Cheesecake</p>
        </div>
        <div className="menu-item">
          <img src={comingsoon} alt="Dessert Item" />
          <p>Macarons</p>
        </div>
        <div className="menu-item">
          <img src={comingsoon} alt="Dessert Item" />
          <p>Ice Cream Sundae</p>
        </div>
      </div>
    </div>
  );
};

export default Desserts;
